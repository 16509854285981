import { animated, useSpring } from "@react-spring/web";

function Anmi() {

    const [springs, api] = useSpring(()=>({
        from: {
            x: 0,
            oo: 1000,
        },
    }))

    const handleClick = () => {
        api.start({
          from: {
            x: 0,
            oo: 1000,
          },
          to: {
            x: 100,
            oo: 2000,
          },
        })
      }
    

    return (
        <div>
            <animated.div
                onClick={handleClick}
                style={{
                    width: 80,
                    height: 80,
                    background: '#ff6d6d',
                    borderRadius: 8,
                    x: springs.x
                }}
            />
        </div>
    );
}
export { Anmi }