import { LocationOnOutlined } from "@mui/icons-material"
import { ReactElement } from "react"

const ExperienceItem = (
    {
        companyName,
        duringTime,
        role,
        companyLogo,
        address,
        techStack,
    }: {
        companyName: string,
        duringTime: string,
        role: string,
        companyLogo: ReactElement,
        techStack: ReactElement[],
        address: string,

    }) => {


    return (
        <div className='w-1/2 max-w-[1000px] flex justify-center items-stretch shadow-md rounded-xl bg-stone-100 p-5'>
            <div className='w-1/5 p-6'>
                {companyLogo}
            </div>
            <div className='flex-1 p-6 text-4xl'>
                <h3 className='font-bold text-[48px] text-stone-600'>{ companyName }<span className='ml-10 text-[24px] text-stone-500'>{ duringTime }</span></h3>
                
                <p className='text-[24px] text-stone-500'>{ role }</p>
                <div className='flex justify-start items-center py-3'>
                    {
                        techStack.map((t, k) => (
                            <span key={k} className='max-w-[10%] inline-block pr-6'>
                                { t }
                            </span> 
                        ))
                    }
                </div>
                <div className="text-[24px] text-stone-500">
                    <LocationOnOutlined fontSize="large"/>
                    <span className="ml-4">{ address }</span>
                </div>
            </div>
        </div>
    )
}


export default ExperienceItem;
