import React, { ReactNode } from 'react'
import "./SkillBadge.scss"

const SkillBadge = ({children}: {children: ReactNode}) => {
  return (
    // <div  className='border shadow-md rounded-full w-[10em] h-[10em] flex justify-center items-center p-[.5em] bg-slate-50'>
    <div  className="max-w-[10em] max-h-[10em] flex justify-center items-center p-[.5em]">
      {children}
    </div>
  )
}

export default SkillBadge