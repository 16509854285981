class Vector {
    constructor(x, y, z) {
        this.x = x;
        this.y = y;
        this.z = z;
    }

    add(v) {
        this.x += v.x;
        this.y += v.y;
        this.z += v.z;
    }

    scale(n) {
        this.x *= n;
        this.y *= n;
        this.z *= n;
    }
}

export default Vector;