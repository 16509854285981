
import { useEffect, useRef, useState } from "react";
import { getPostByHash } from "../../../api/requests";
import { marked } from "marked";

import "github-markdown-css/github-markdown-light.css";
import { useParams } from "react-router";
import BlockLoading from "../../../component/BlockLoading";
import { Post } from "../BlogTypes";


function PostDetail() {

  const [post, setPost] = useState<Post>();
  const mdRef = useRef<HTMLDivElement>(null);

  const { hash } = useParams()
  const [postLoading, setPostLoading] = useState(true);


  useEffect(() => {
    if (!hash) return;
    setPostLoading(true);
    getPostByHash(hash)
      .then((res) => {
        setPost(res.data.data);
      }).catch(e => {
        console.log(e);
      })
      .finally(() => {
        setPostLoading(false);
      })
  }, [hash]);

  useEffect(() => {
    if (!post) return;
    const { rawWithoutTitle } = post;
    if (rawWithoutTitle) {
      mdRef.current!.innerHTML = marked.parse(rawWithoutTitle)
    }
  }, [post])

  return (
    <div className="panel-main post">
      {postLoading && <BlockLoading />}
      <h1>{post?.title}</h1>
      <div className='markdown-body' style={{ fontSize: "1em" }} ref={mdRef}></div>
    </div>
  );
}
export { PostDetail }


