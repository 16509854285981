import { useEffect, useMemo, useState } from "react";

export const useSectionPanelScrollPercent = ( el: HTMLElement | null ): number =>{

  const [scrolled, setScrolled] = useState(0);

  const screenHeight: number = window.innerHeight;
  // if( !el ) return [-1, screenHeight];
  // const children: HTMLCollection = el.parentElement!.children;
  // const elIndex: number = Array.from(children).indexOf.call(children,el);
  
  const showStartPosi: number = el ? el.offsetTop - screenHeight : 0;
  const showEndPosi: number = el ? el.offsetHeight + el.offsetTop : screenHeight;

  useEffect(() => {
    const scrollHandler: EventListener = ( ev: Event ): any=>{
      const target:Document = ev.target as Document;
      
      setScrolled( target.scrollingElement?.scrollTop || 0 )
    }
    const win: Window = window;
    win.addEventListener("scroll", scrollHandler);
    return ()=>{
      win.removeEventListener( "scroll", scrollHandler );
    }
  }, []);

  const targetElementScrollPercentage = useMemo(() => {
    if( scrolled >= showStartPosi && scrolled <= showEndPosi ){
      return ((scrolled - showStartPosi)/(showEndPosi - showStartPosi) * 100)
    }
    return 0;
  }, [scrolled, showEndPosi, showStartPosi]);

  
  return targetElementScrollPercentage;
}


