import React from 'react';
import { createRoot } from 'react-dom/client';
// import "normalize.css"
import './index.scss';
import App from './App';

// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';

import reportWebVitals from './reportWebVitals';


const root = createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)


reportWebVitals();
