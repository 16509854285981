import React from 'react'

const BlogExample = () => {
  return (
    <div className="panel flex justify-center items-center bg-stone-300">
      <span className="absolute right-0 top-0 text-[200px] font-bold text-stone-200 z-0">Blog</span>
      <div className='w-full p-20 z-1 relative'>
        <h3 className='p-10 font-bold text-stone-500 text-[32px]'>最新的文章：</h3>
        <div className='flex justify-center items-start'>
          
          <div className='bg-white w-1/3 max-w-[700px] max-h-[600px] p-10 shadow-md rounded-xl m-10 overflow-hidden'>
            <h4 className='font-bold text-2xl text-stone-600'>This is article title.This is article title.This is article title.This is article title.</h4>
            <p>
              This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.
              This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.
              This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.
              This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.
              This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.
              This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.

            </p>
          </div>
          <div className='bg-white w-1/3 max-w-[700px] max-h-[600px] p-10 shadow-md rounded-xl m-10 overflow-hidden'>
            <h4 className='font-bold text-2xl text-stone-600'>This is article title.This is article title.This is article title.This is article title.</h4>
            <p>
              This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.
              This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.
              This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.

            </p>
          </div>
          <div className='bg-white w-1/3 max-w-[700px] max-h-[600px] p-1 shadow-md rounded-xl m-10 overflow-hidden'>
            <h4 className='font-bold text-2xl text-stone-600'>This is article title.This is article title.This is article title.This is article title.</h4>
            <p>
              This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.
              This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.
              This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.This is article content.

            </p>
          </div>


          


        </div>
      </div>
    </div>
  )
}

export default BlogExample