import React from 'react';
import { Link } from 'react-router-dom';

function BlogMenu() {
  return (
    <nav className="menu">
      <Link to={"/"}>Home</Link>
      {/* <Link to={"/experiment"}>实验室</Link> */}
      {/* <Link to={"/about"}>About</Link> */}
      {/* <Link to={"/manage"} target="_blank">Admin</Link> */}
    </nav>
  );
}

export { BlogMenu };