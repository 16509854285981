import React from 'react'

const VueIcon = ({width = '200'}: {width?: string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 64 64"
    >
      <path fill="none" d="M-512 -128H768V672H-512z"></path>
      <g>
        <g>
          <path
            fill="#435466"
            d="M17.595 11.204h8.91l5.536 9.391 5.591-9.391h8.831L32.041 36.563 17.595 11.204z"
          ></path>
          <path
            fill="#4dba87"
            d="M8.089 11.204l23.952 41.845 24.126-41.845h-9.704L32.041 36.563 17.595 11.204H8.089z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default VueIcon