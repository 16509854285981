import { Fragment } from "react";
import { Route, Routes } from "react-router";
import BlogHome from "./homePostList/HomePostList";
import BlogFrame from "./blogFrame/BlogFrame";
import { PostDetail } from "./post/PostDetail";
import { About } from "./about/About";

function RoutesBlog() {

  return (
    <Fragment>
      {/* <Route path="about" element={<About />}></Route> */}
      <Route path="post/:hash" element={<PostDetail />}></Route>
      <Route path="" element={<BlogHome />}></Route>
    </Fragment>
  )
}

export default RoutesBlog;