import React from 'react';
import { Link } from 'react-router-dom';
import { Post } from '../../BlogTypes';

import "./HomeListItem.scss";

type ItemProp = {
  post: Post,
}


function HomeListItem(props: ItemProp) {
  const { postHash, title, createdDate} = props.post;
  return (
    <li className='home-list-item'>
      <Link className='link' to={`post/${postHash}`}>{title}</Link>
      <small>{ createdDate }</small>
    </li>
  );
}

export { HomeListItem };
