import { Fragment } from "react";
import { Route } from "react-router";
import { UploadPost } from "./uploadPost/UploadPost";
import Editor from "./editor/Editor";
import ArticleManage from "./articleManage/ArticleManage";


function Manage() {
  
  return (
    <Fragment>
      <Route path="upload" element={ <UploadPost /> }></Route>
      <Route path="editor" element={ <Editor /> }></Route>
      <Route path="article" element={ <ArticleManage /> }></Route>
      <Route path="" element={ <UploadPost /> }></Route>
    </Fragment>
  )
}

export default Manage;