import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import "./ManageFrame.scss"
import { getToken } from "../../../common/util";
import { Ax, AxUpload } from "../../../api/Ax";
import { KEY_AUHTORIZATION } from "../../../common/static";

import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import { Outlet, useHref, useLocation, useResolvedPath } from "react-router";


import { Article, FileUpload, Leaderboard, Settings } from "@mui/icons-material";
import { useState } from "react";
import { Link } from 'react-router-dom';


type frameProp = {
  children?: React.ReactNode
}

type menuItem = {
  text: string
  icon: React.ReactElement
  path: string
}

const drawerWidth = 240;

function ManageFrame(props: frameProp) {


  Ax.defaults.headers.common = {
    [KEY_AUHTORIZATION]: `Bearer ${getToken()}`
  }

  AxUpload.defaults.headers.common = {
    [KEY_AUHTORIZATION]: `Bearer ${getToken()}`
  }
  

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const location = useLocation();

  const [menuData, setMenuData] = useState([
    {
      text: "Upload",
      icon: <FileUpload />,
      path: "upload",
    },
    {
      text: "网站统计",
      icon: <Leaderboard />,
      path: "dashboard",
    },

    {
      text: "文章管理",
      icon: <Article />,
      path: "article",
    },
    {
      text: "网站设置",
      icon: <Settings />,
      path: "settings",
    }

  ]);

  return (
    <div className="manage-frame">
      <Box sx={{ display: 'flex' }}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <List>
            {
              menuData.map((menu: menuItem, index) => (
                <ListItem key={menu.text} disablePadding>
                  <ListItemButton
                    selected={ `/manage/${menu.path}` === location.pathname || ( location.pathname === "/manage" && index === 0) }
                    component={Link as any}
                    to={menu.path}
                    state={{xxx: menu.text}}
                    
                  >
                    <ListItemIcon>
                      {menu.icon}
                    </ListItemIcon>
                    <ListItemText primary={
                      menu.text
                    } />
                  </ListItemButton>
                </ListItem>
              ))
            }
          </List>
          <Toolbar />

        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, height: "100vh", margin: "0 auto" }}
        >
          <Outlet />
        </Box>
      </Box>
    </div >
  );
}


export default ManageFrame;