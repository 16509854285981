import { Button, Card, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { login } from "../../../api/requests";
import { getToken, saveToken } from "../../../common/util";
import "./Login.scss";
import SiteLogo from "../../../component/SiteLogo";

function Login() {

  const [ token, setToken ] = useState(getToken());
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();


  useEffect(()=>{
    if (!token) return;
    saveToken(token);
    navigate("/Manage");
  },[token])

  const handleLogin = useCallback(()=>{

    login( {username, password} )
    .then((res)=>{
      const { data } = res.data;
      setToken(data);

    })
    .catch(e=>{
      alert(e.message);
    })

  },[username,password])
  
  return (
    <div className="b-login">
      <Card className="b-form"> 

        <div className="logo-box">
          <SiteLogo />
        </div>
        
        {/* <Typography component="h1" variant="h5" color="#666">Admin</Typography> */}
        <TextField 
          fullWidth 
          margin="normal" 
          label="username" 
          variant="outlined" 
          autoFocus={true}
          onChange={(e)=>{setUsername(e.target.value)}}
          
          />
        <TextField 
          fullWidth 
          type="password" 
          margin="normal" 
          label="password" 
          variant="outlined" 
          onChange={(e)=>{setPassword(e.target.value)}}
          />

        <Button fullWidth size="large" variant="contained" onClick={ handleLogin }>Login</Button>
      </Card>
      
    </div>
  )
}

export default Login;