import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Navigate } from "react-router";


function AuthenRequired( props : { children: ReactJSXElement } ) {
  

  const token: string | null | undefined = localStorage.getItem("token");

  if( token ){
     return props.children
  }
  
  return <Navigate to="/manage/login" /> 
}

export default AuthenRequired;

