import React from 'react'
import FastlaneIcon from '../../../../common/jobs/Fastlane'
import WecarIcon from '../../../../common/jobs/Wecar'
import DashiIcon2 from '../../../../common/jobs/Dashi2'
import H5Icon from '../../../../common/logo/H5Icon'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VueIcon from '../../../../common/logo/VueIcon'
import Css3Icon from '../../../../common/logo/Css3Icon'
import ExperienceItem from './ExperienceItem'
import Fastlane1 from '../../../../common/jobs/Fastlane1'


const ExperiencePanel = () => {

    return (
        <div className='w-full experience-panel relative'>
            <span className="absolute top-0 left-0 z-0 text-[#EFEFEF] text-[200px] font-bold">Experience</span>
            <div className='panel flex justify-center items-center'>
                <ExperienceItem
                    companyName='WeCar'
                    companyLogo={<WecarIcon />}
                    duringTime='2010-2013'
                    techStack={[
                        <H5Icon width='40'/>,
                        <Css3Icon width='40' />,
                        <VueIcon width='40'/>,
                    ]}
                    role='Frontend developer'
                    address='ShenZhen'
                />
            </div>

            <div className='panel flex justify-center items-center'>
                <ExperienceItem
                    companyName='WeCar'
                    companyLogo={<DashiIcon2 />}
                    duringTime='2010-2013'
                    techStack={[
                        <H5Icon width='40'/>,
                        <Css3Icon width='40' />,
                        <VueIcon width='40'/>,
                    ]}
                    role='Frontend developer'
                    address='ShenZhen'
                />
            </div>

            <div className='panel flex justify-center items-center'>
                <ExperienceItem
                    companyName='Fastlane'
                    companyLogo={<Fastlane1 />}
                    duringTime='2010-2013'
                    techStack={[
                        <H5Icon width='40'/>,
                        <Css3Icon width='40' />,
                        <VueIcon width='40'/>,
                    ]}
                    role='Frontend developer'
                    address='ShenZhen'
                />
            </div>

            <div className='panel flex justify-center items-center'>
                <ExperienceItem
                    companyName='?'
                    companyLogo={<Fastlane1 />}
                    duringTime='2010-2013'
                    techStack={[
                        <H5Icon width='40'/>,
                        <Css3Icon width='40' />,
                        <VueIcon width='40'/>,
                    ]}
                    role='Fullstack developer'
                    address='Tokyo'
                />
            </div>

        </div>
    )
}

export default ExperiencePanel;