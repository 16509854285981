import Css3Icon from '../../../../common/logo/Css3Icon';
import DockerIcon from '../../../../common/logo/DockerIcon';
// import ExIcon from '../../../../common/logo/ExIcon';
import GitIcon from '../../../../common/logo/GitIcon';
import H5Icon from '../../../../common/logo/H5Icon';
import JavaIcon from '../../../../common/logo/JavaIcon';
import JsIcon from '../../../../common/logo/JsIcon';
import MysqlIcon from '../../../../common/logo/MysqlIcon';
import NextIcon from '../../../../common/logo/NextIcon';
import NodeIcon from '../../../../common/logo/NodeIcon';
import ReactIcon from '../../../../common/logo/ReactIcon';
import SpringIcon from '../../../../common/logo/SpringIcon';
import TsIcon from '../../../../common/logo/TsIcon';
import VueIcon from "../../../../common/logo/VueIcon";
import SkillBadge from '../skillBadge/SkillBadge';

const SkillPanel = () => {
  return (
    <div className="panel relative flex justify-center items-center">
        <span className="absolute top-0 left-0 z-0 text-[#EFEFEF] text-[200px] font-bold">Skill</span>
        <div className='relative z-10 flex flex-wrap max-w-[1200px] h-full justify-center items-center content-center  p-[2em]'>
            <div className="flex-initial m-[2em]"><SkillBadge><JsIcon/></SkillBadge></div>
            <div className="flex-initial m-[2em]"><SkillBadge><TsIcon/></SkillBadge></div>
            <div className="flex-initial m-[2em]"><SkillBadge><ReactIcon/></SkillBadge></div>
            <div className="flex-initial m-[2em]"><SkillBadge><NextIcon/></SkillBadge></div>
            <div className="flex-initial m-[2em]"><SkillBadge><VueIcon/></SkillBadge></div>
            <div className="flex-initial m-[2em]"><SkillBadge><H5Icon/></SkillBadge></div>
            <div className="flex-initial m-[2em]"><SkillBadge><Css3Icon/></SkillBadge></div>
            <div className="flex-initial m-[2em]"><SkillBadge><NodeIcon/></SkillBadge></div>
            {/* <div className="flex-initial m-[2em]"><SkillBadge><ExIcon/></SkillBadge></div> */}
            <div className="flex-initial m-[2em]"><SkillBadge><JavaIcon/></SkillBadge></div>
            <div className="flex-initial m-[2em]"><SkillBadge><SpringIcon/></SkillBadge></div>
            <div className="flex-initial m-[2em]"><SkillBadge><GitIcon/></SkillBadge></div>
            <div className="flex-initial m-[2em]"><SkillBadge><DockerIcon/></SkillBadge></div>
            <div className="flex-initial m-[2em]"><SkillBadge><MysqlIcon/></SkillBadge></div>
        </div>
    </div>
  )
}

export default SkillPanel