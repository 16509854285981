import { useCallback, useEffect, useRef, useState } from "react";
import Galaxy from "../../component/canvas/Galaxy";
import "./Welcome.scss";



const Welcome = () => {

    useEffect(()=>{

       
    })

    return (
        <div className="relative w-full h-[100vh]">
            <div className="absolute -z-10">
                <Galaxy />
            </div>
            <div className="w-full h-[100vh]">
                <h1 className="text-white text-2xl flashing text-center absolute bottom-[35%] w-full">
                    <span>ページは地球に向かっています〜</span>
                </h1>
            </div>
        </div>
    );

}
 
export default Welcome;