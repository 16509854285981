import { useCallback, useState } from "react";
import { Card, Fab } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import fm from "front-matter";
import { KEY_EXCERPT_SPLITER } from "../../../common/static";
import { savePost, uploadPost } from "../../../api/requests";
import "./Editor.scss"
import MDEditor from "@uiw/react-md-editor";
import { Post } from "../MangeTypes";

function Editor() {


  const [md, setMd] = useState("");

  const handleChange = useCallback((value: string) => {
    setMd(value);
  }, []);

  // save artice
  const handleSave = useCallback(() => {
    const H1REG = /\n?#\s+[\S｜ ｜\t]*\n/;
    const { attributes, body, frontmatter = "" } = fm(md);

    let title: string = "";
    let excerpt: string = "";
    let contentWithoutExcerpt: string = "";
    let contentWithExcerpt: string = "";

    const matched = H1REG.exec(body);
    const h1Md = matched && matched[0] || "";
    title = h1Md.replace(/\# /, "");

    contentWithExcerpt = body.replace(h1Md, "");
    const contentSplitByMore = contentWithExcerpt.split(KEY_EXCERPT_SPLITER);

    if (contentSplitByMore.length === 1) {
      contentWithoutExcerpt = contentWithExcerpt
    } else {
      excerpt = contentSplitByMore[0];
      contentWithoutExcerpt = contentSplitByMore[1];
    }


    const post: Post = {
      // title: attributes.title,
      title: title.trim(),
      content: contentWithExcerpt.trim(),
      excerpt: excerpt.trim(),
      contentWithoutExcerpt: contentWithoutExcerpt.trim(),
      markdownRaw: md.trim(),
      mdWithoutFrontMatter: body,
      isDraft: true,
    }

    savePost(post)
      .then(res => {
        console.log(res);
      })

  }, [md])


  return (
    <div className="editor-page">
      <Card>
        {/* <CardContent> */}
        <div style={{ fontSize: "24px" }}>
          <MDEditor
            height={700}
            value={md}
            onChange={(val) => { val !== undefined && setMd(val) }}
            hideToolbar={true}
            preview="edit"
          />
        </div>

        {/* </CardContent> */}
      </Card>
      <Fab className="save-btn" onClick={handleSave}>
        <SaveIcon />
      </Fab>
    </div>
  );
}

export default Editor;