import { UploadFile, UploadFileRounded } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';
import React, { useCallback } from 'react';
import "./UploadPost.scss";
import { uploadPost } from '../../../api/requests';

function UploadPost() {

  const uploadMd = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = ev.target;
      const postForm = new FormData();
      postForm.append("postMd", files![0]);
      
      uploadPost( postForm );
    },
    [],
  )
  

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "100vh",
        width: "100%",
        padding: "1em"
      }}
    >
      <label className='upload-label'>
        <div style={{textAlign: "center"}}>
          <UploadFileRounded sx={{ fontSize: "120px" }} />
          <div>点击选择文件或拖动文件到这里</div>
        </div>

        <input 
          onChange={ uploadMd }
          hidden 
          accept=".md" 
          type="file"/>
      </label>

    </Grid>


  );
}

export { UploadPost };