import React from 'react'

const Wecar = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <g fill="none" stroke="#99d7a8" strokeWidth="2">
                <path
                    d="M46.56 0Q24.04 2.44 11.02 18.9C-3.17 36.85-3.52 61.66 10.19 80q12.92 17.29 35.97 20M53.87 0q28.48 3.08 41.32 28.01 3.72 7.24 4.81 16.58M100 55.54C96.66 79.74 78.66 97.59 54.34 100"
                    vectorEffect="non-scaling-stroke"
                ></path>
                <path
                    d="M57.7 61.95q-6.64.09-13.57.05-8.55-.04-8.87-8.18C35 47.34 34 38.05 43.47 38.01q7.05-.04 14.02.04c4.43.05 6.06 2.61 7.69 6.15q.26.56.82.8 6.69 2.82 13.51-.15a1.01 1-11.5 00.6-.9c.09-6.31.46-12.91-.13-19.11-.39-4.09-4.16-5.15-7.68-4.6q-23.04 3.53-45.17-.2c-3.9-.66-6.96 2.35-6.95 6.24q.03 19.78.04 40.38a.34.34 0 00.54.27c8.22-6.21 17.88 3.27 12.91 12.02q-.42.75.43.65 16.04-1.82 32-.01.81.09.42-.63c-4.84-8.99 4.56-17.77 13.09-12.03a.36.36 0 00.56-.3l.03-10.61a.86.86 0 00-.5-.78q-6.97-3.25-13.87-.12-.54.25-.76.8c-1.37 3.44-3.3 5.97-7.37 6.03"
                    vectorEffect="non-scaling-stroke"
                ></path>
                <path
                    d="M59.305 47.492a2.82 2.82 0 00-2.78-2.859l-.4-.005a2.82 2.82 0 00-2.86 2.78l-.07 5.1a2.82 2.82 0 002.78 2.859l.4.005a2.82 2.82 0 002.86-2.78l.07-5.1M47.118 47.401a2.71 2.71 0 00-2.69-2.729l-.6-.004a2.71 2.71 0 00-2.73 2.691l-.036 5.2a2.71 2.71 0 002.69 2.729l.6.004a2.71 2.71 0 002.73-2.691l.036-5.2M32.22 74a6.03 6.03 0 00-6.03-6.03A6.03 6.03 0 0020.16 74a6.03 6.03 0 006.03 6.03A6.03 6.03 0 0032.22 74M80.12 73.98a6.05 6.05 0 00-6.05-6.05 6.05 6.05 0 00-6.05 6.05 6.05 6.05 0 006.05 6.05 6.05 6.05 0 006.05-6.05"
                    vectorEffect="non-scaling-stroke"
                ></path>
            </g>
            <path
                fill="#33af51"
                d="M46.56 0h7.31q28.48 3.08 41.32 28.01 3.72 7.24 4.81 16.58v10.95C96.66 79.74 78.66 97.59 54.34 100h-8.18q-23.05-2.71-35.97-20c-13.71-18.34-13.36-43.15.83-61.1Q24.04 2.44 46.56 0zM57.7 61.95q-6.64.09-13.57.05-8.55-.04-8.87-8.18C35 47.34 34 38.05 43.47 38.01q7.05-.04 14.02.04c4.43.05 6.06 2.61 7.69 6.15q.26.56.82.8 6.69 2.82 13.51-.15a1.01 1-11.5 00.6-.9c.09-6.31.46-12.91-.13-19.11-.39-4.09-4.16-5.15-7.68-4.6q-23.04 3.53-45.17-.2c-3.9-.66-6.96 2.35-6.95 6.24q.03 19.78.04 40.38a.34.34 0 00.54.27c8.22-6.21 17.88 3.27 12.91 12.02q-.42.75.43.65 16.04-1.82 32-.01.81.09.42-.63c-4.84-8.99 4.56-17.77 13.09-12.03a.36.36 0 00.56-.3l.03-10.61a.86.86 0 00-.5-.78q-6.97-3.25-13.87-.12-.54.25-.76.8c-1.37 3.44-3.3 5.97-7.37 6.03zm1.605-14.458a2.82 2.82 0 00-2.78-2.859l-.4-.005a2.82 2.82 0 00-2.86 2.78l-.07 5.1a2.82 2.82 0 002.78 2.859l.4.005a2.82 2.82 0 002.86-2.78l.07-5.1zm-12.187-.09a2.71 2.71 0 00-2.69-2.73l-.6-.004a2.71 2.71 0 00-2.73 2.691l-.036 5.2a2.71 2.71 0 002.69 2.729l.6.004a2.71 2.71 0 002.73-2.691l.036-5.2zM32.22 74a6.03 6.03 0 00-6.03-6.03A6.03 6.03 0 0020.16 74a6.03 6.03 0 006.03 6.03A6.03 6.03 0 0032.22 74zm47.9-.02a6.05 6.05 0 00-6.05-6.05 6.05 6.05 0 00-6.05 6.05 6.05 6.05 0 006.05 6.05 6.05 6.05 0 006.05-6.05z"
            ></path>
            <path
                fill="#fff"
                d="M57.7 61.95c4.07-.06 6-2.59 7.37-6.03q.22-.55.76-.8 6.9-3.13 13.87.12a.86.86 0 01.5.78l-.03 10.61a.36.36 0 01-.56.3c-8.53-5.74-17.93 3.04-13.09 12.03q.39.72-.42.63-15.96-1.81-32 .01-.85.1-.43-.65c4.97-8.75-4.69-18.23-12.91-12.02a.34.34 0 01-.54-.27q-.01-20.6-.04-40.38c-.01-3.89 3.05-6.9 6.95-6.24q22.13 3.73 45.17.2c3.52-.55 7.29.51 7.68 4.6.59 6.2.22 12.8.13 19.11a1.01 1-11.5 01-.6.9Q72.69 47.82 66 45q-.56-.24-.82-.8c-1.63-3.54-3.26-6.1-7.69-6.15q-6.97-.08-14.02-.04c-9.47.04-8.47 9.33-8.21 15.81q.32 8.14 8.87 8.18 6.93.04 13.57-.05z"
            ></path>
            <rect
                width="6.04"
                height="10.74"
                x="-3.02"
                y="-5.37"
                fill="#fff"
                rx="2.82"
                transform="rotate(.8 -3552.803 4053.544)"
            ></rect>
            <rect
                width="6.02"
                height="10.62"
                x="-3.01"
                y="-5.31"
                fill="#fff"
                rx="2.71"
                transform="rotate(.4 -7137.034 6340.392)"
            ></rect>
            <circle cx="26.19" cy="74" r="6.03" fill="#fff"></circle>
            <circle cx="74.07" cy="73.98" r="6.05" fill="#fff"></circle>
        </svg>
    );
}

export default Wecar