import { useRef } from "react";
import { useSectionPanelScrollPercent } from "../../common/sHooks";
import "./Home.css";
import { HeadPanel } from "./piece/headPanel/HeadPanel";
import SkillPanel from "./piece/skillPanel/SkillPanel";
import AboutMePanel from "./piece/aboutMePanel/AboutMePanel";
import ExperiencePanel from "./piece/experiencePanel/ExperiencePanel";
import HobbiesPanel from "./piece/hobbiesPanel/HobbiesPanel";
import BlogExample from "./piece/blogExample/BlogExample";

function Home() {

  const section1 = useRef(null);
  const section2 = useRef(null);

  const p1Per = useSectionPanelScrollPercent(section2.current);

  return (
    <div className="home">
      <HeadPanel />
      <AboutMePanel />
      <SkillPanel />
      <ExperiencePanel />
      <HobbiesPanel />
      <BlogExample />

      {/* section 2 */}
      {/* <section ref={ section2 }>
        <div className="panel sticky">
          hello 2
          <span className="background" style={{}}></span>
        </div>
      </section> */}

      

    </div>

  )

}

export default Home;

function retun(arg0: number) {
  throw new Error("Function not implemented.");
}
