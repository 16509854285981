import React from 'react';
import logo from './logo.svg';
import './App.scss';
import Home from './page/home/Home';
import AppFrame from './component/AppFrame';
import BlogHome from './page/blog/homePostList/HomePostList';
import { PostDetail } from './page/blog/post/PostDetail';

import { BrowserRouter as Router, redirect } from "react-router-dom";
import { Route, Routes, useNavigate } from "react-router";
import RoutesBlog from './page/blog/RoutesBlog';
import BlogFrame from './page/blog/blogFrame/BlogFrame';
import Manage from './page/manage/Manage';
import ManageFrame from './page/manage/component/ManageFrame';
import Login from './page/manage/login/Login';
import AuthenRequired from './component/AuthenRequired';
import { Anmi } from './page/Anmi';
import Welcome from './page/welcome/Welcome';
import RedirectToWelcome from './component/RedirectToWelcome';



function App() {
  
  return (
    <AppFrame>
      <Router>
        <Routes>
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/" element={<RedirectToWelcome />} />
          <Route path="/*" element={<RedirectToWelcome />} />
          
          <Route path="home" element={<Home />} />
          <Route path="anmi" element={<Anmi />} />
          <Route path="" element={<BlogFrame></BlogFrame>}>{RoutesBlog()}</Route>
          <Route path="manage/login" element={<Login />}></Route>
          <Route path="manage/*" element={
            <AuthenRequired>
              <ManageFrame></ManageFrame>
            </AuthenRequired>
          }>
            { Manage() }
          </Route>
        </Routes>

        {/* <BlogHome /> */}
        {/* <Post /> */}
      </Router>
    </AppFrame>
  );
}

export default App;
