import React from 'react'
// import "./AboutMePanel.scss"

export default function AboutMePanel() {
    return (
        <div className="panel flex justify-center items-center bg-[#D3C7A8]">
                <span className="absolute right-0 top-0 text-[200px] font-bold text-[#CCC0A2] z-0">About</span>
            <div className="relative w-3/5 h-3/5 mx-w-[1200px] mx-h-[800px] rounded-xl shadow-md bg-white flex justify-center items-stretch overflow-hidden">
                <div className="relative w-2/5 bg-slate-400 box-border flex">
                    xxx
                </div>
                <div className="w-3/5 relative">
                    <div className="flex items-center h-full pr-[10em]">
                        <p className="z-10 px-10 text-2xl text-stone-600 leading-relaxed bottom-0">
                            我叫LI JUNWEI。 我毕业于国内的一所名字非常响亮的野鸡大学。
                            <br />
                            <br />
                            从事web开发7年。前端后端开发都可以。比较擅长的是前端开发，做过前端技术leader。
                            <br />
                            <br />
                            
                            我喜欢折腾一些小东西：Android刷机、软路由Openwrt、黑苹果Hackintosh...。我的生活像是程序。
                            <br />
                            
                            <br />
                            我有一只名字叫SHUINI的猫。
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}
