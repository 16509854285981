import Vector from "./Vector";


class Particle {
    constructor(x, y, z, P_CTX, P_XO, P_YO, Z_SPD, P_MAX_Z, P_MAX_R, P_W, P_H) {
        this.XO = P_XO;
        this.YO = P_YO;
        this.MAX_Z = P_MAX_Z;
        this.MAX_R = P_MAX_R;
        this.CTX = P_CTX;
        this.W = P_W;
        this.H = P_H;
        const X_VEL = 0, Y_VEL = 0, Z_VEL = -Z_SPD;
        this.pos = new Vector(x, y, z);
        this.vel = new Vector(X_VEL, Y_VEL, Z_VEL);
        this.vel.scale(0.005);
        this.fill = "rgba(255,255,255,0.5)";
        this.stroke = this.fill;
    }

    to2d(v) {
        const X_COORD = v.x - this.XO,
          Y_COORD = v.y - this.YO,
          PX = X_COORD / v.z,
          PY = Y_COORD / v.z;
        return [PX + this.XO, PY + this.YO];
      }

    update() {
        this.pos.add(this.vel);
    }

    render() {
        const PIXEL = this.to2d(this.pos),
            X = PIXEL[0],
            Y = PIXEL[1],
            R = (this.MAX_Z - this.pos.z) / this.MAX_Z * this.MAX_R;

        if (X < 0 || X > this.W || Y < 0 || Y > this.H) this.pos.z = this.MAX_Z;

        this.update();
        this.CTX.beginPath();
        this.CTX.fillStyle = this.fill;
        this.CTX.strokeStyle = this.stroke;
        this.CTX.arc(X, PIXEL[1], R, 0, Math.PI * 2);
        this.CTX.fill();
        this.CTX.stroke();
        this.CTX.closePath();
    }
}

export default Particle