import React from 'react'

const Fastlane1 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" fill="none">
            <circle cx="250" cy="250" r="250" fill="#FD0000" />
            <path d="M372.936 100L363.752 160.41H250.478C237.293 162.785 232.735 167.424 229.048 180.887L220.884 225.939L161.696 256.655L209.659 288.396L190.27 400H127L166.182 169.625C180.888 125.563 193.595 108.507 229.048 100H372.936Z" fill="white" />
            <path d="M293.339 321.16L315.789 195.222L197.413 256.655L293.339 321.16Z" fill="white" />
            <path d="M372.936 100L363.752 160.41H250.478C237.293 162.785 232.735 167.424 229.048 180.887L220.884 225.939L161.696 256.655L209.659 288.396L190.27 400H127L166.182 169.625C180.888 125.563 193.595 108.507 229.048 100H372.936Z" stroke="white" />
            <path d="M293.339 321.16L315.789 195.222L197.413 256.655L293.339 321.16Z" stroke="white" />
        </svg>
    )
}

export default Fastlane1