import { 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Switch, 
  Button, 
  Dialog, 
  DialogContent, 
  DialogActions 
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getPostByHash, getPostList, updatePost } from "../../../api/requests";
import MDEditor from "@uiw/react-md-editor";
import { Post } from "../MangeTypes";

type ArticleSimple = {
  id: number,
  title: string,
  excerpt: string,
  draft: boolean,
  postHash: string,

}

function ArticleManage() {

  const [list, setList] = useState<ArticleSimple[]>([]);

  const [editingPost, setEditingPost] = useState<Post>();

  const [open, setOpen] = useState(false);


  const tableMarkup = useMemo(() => {
    return list.map((ar, index) => (
      <TableRow
        key={index}
      >
        <TableCell component="th" scope="row">
          {ar.title}
        </TableCell>
        <TableCell align="left">{ar.excerpt}</TableCell>
        <TableCell>
          <Button
            onClick={() => { editPostHandler(ar.postHash) }}
          >Edit</Button>
        </TableCell>
        <TableCell align="right">
          <Switch
            checked={!ar.draft}
            onChange={() => { updatePostHandler(index, { draft: !ar.draft }, ar.postHash) }}
            color="primary"
            inputProps={{ 'aria-label': 'visible' }}
          />
        </TableCell>

      </TableRow>
    ))
  }, [list]);

  const updatePostHandler = useCallback(
    (index: number, updateAttrs: any, postHash: string) => {
      updatePost(postHash, updateAttrs)
        .then(() => {
          const newList = list.map((item, idx) => (
            idx === index ? { ...item, ...updateAttrs } : item
          ))

          setList(newList);
        })
    },
    [list],
  )


  useEffect(() => {
    getPostList()
      .then(res => {
        setList(res.data.data)
      })
  }, []);

  const handleClose = useCallback(
    () => {
      setOpen(false);
    },
    [],
  )

  const handleSave = useCallback(
    () => {
      updatePost(editingPost!.postHash!, { markdownRaw: editingPost!.markdownRaw })
        .then(() => {
          setOpen(false);
        })
    },
    [editingPost],
  )

  const editPostHandler = useCallback(
    (hash: string) => {
      getPostByHash(hash)
        .then((data) => {
          setEditingPost(data.data.data);
          setOpen(true);
        })
    },
    [],
  )


  return (
    <div style={{ margin: "0 auto", padding: "1em" }}>
      <TableContainer component={Paper}>
        <Table
          aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>title</TableCell>
              <TableCell>excerpt</TableCell>
              <TableCell></TableCell>
              <TableCell align="right">visiable</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableMarkup}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <MDEditor
            height={700}
            value={editingPost?.markdownRaw}
            onChange={(val) => { val !== undefined && setEditingPost({ ...editingPost!, markdownRaw: val }) }}
            hideToolbar={true}
            preview="edit"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>

  )

}



export default ArticleManage;
