
import { Post } from "../page/manage/MangeTypes"
import { Ax, AxUpload } from "./Ax"
import * as REQUEST_URL from "./urlConfig"

export const login = (data: { username: string, password: string }) => {
  return Ax.post(
    REQUEST_URL.LOGIN,
    JSON.stringify(data))
}

export const getPostByHash = (hash: string) => {
  return Ax.get(`${REQUEST_URL.POST}/${hash}`)
}

export const getPostList = () => {
  return Ax.get(REQUEST_URL.POST_LIST)
}

export const savePost = (post: Post) => {
  return Ax.post(REQUEST_URL.POST, {
    ...post
  })
}

export const updatePost = (postHash: string, post: any) => {
  return Ax.put(`${REQUEST_URL.POST}/${postHash}`, {
    ...post
  })
}

export const uploadPost = (postFormData: FormData) => {
  return AxUpload.post(REQUEST_URL.POST, postFormData)
}
