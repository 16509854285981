import React, { useCallback, useEffect, useRef, useState } from 'react'
import Particle from './Particle';


export default function Galaxy({

}) {

  const canvasRef = useRef(null);

  const [PARTICLES, setPARTICLES] = useState([]);


  const W = window.innerWidth,
    H = window.innerHeight,
    XO = W / 2,
    YO = H / 2,
    NUM_PARTICLES = 20,
    MAX_Z = 2,
    MAX_R = 1,
    Z_SPD = 1
    // PARTICLES = []
    ;

  const isMobile = useCallback((val) => {
    let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return flag;
  }, []);


  function init() {
    if(!canvasRef.current) return;
    const CANVAS = canvasRef.current;
    const CTX = CANVAS.getContext("2d");

    CANVAS.width = isMobile() ? window.screen.availWidth : W;
    CANVAS.height = isMobile() ? window.screen.availHeight : H;
    CTX.clearRect(0, 0, W, H);

    for (let i = 0; i < NUM_PARTICLES; i++) {
      const X = Math.random() * W, Y = Math.random() * H, Z = Math.random() * MAX_Z;
      PARTICLES.push(new Particle(X, Y, Z, CTX, XO, YO, Z_SPD, MAX_Z, MAX_R, W, H));
    }
    
  }


  function loop(CTX) {
    const animaId = requestAnimationFrame(() => loop(CTX));
    CTX.fillStyle = "rgba(0,0,0,0.15)";
    CTX.fillRect(0, 0, W, H);
    for (let i = 0; i < PARTICLES.length; i++) {
      PARTICLES[i].render();
    }
    return animaId;
  }

  function start(CTX) {
    init();
    return loop(CTX);
  }


  useEffect(() => {
    if (canvasRef.current) {
      const CTX = canvasRef.current.getContext('2d');
      
      function eStart() {
        return start(CTX);
      }
      const animaId = eStart();
    }
  }, [canvasRef, W]);



  return (
    <canvas ref={canvasRef} />
  )
}
