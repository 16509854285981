
import "./HeadPanel.scss";

function HeadPanel() {
  
  return (
    <div className="panel head-panel">
      {/* <div>🐈</div> */}
      <div className="w-600">HELLO</div>
      <div>WELCOME</div>
      <div>TO MY WORLD<span>.</span></div>
    </div>
  );
}
export { HeadPanel }