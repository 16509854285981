import { Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import { getPostList } from "../../../api/requests";
import BlockLoading from "../../../component/BlockLoading";
import { HomeListItem } from "./homeListItem/HomeListItem";
import { Post } from "../BlogTypes";

type Props = {
  children?: ReactNode
  className?: string
}


function HomePostList(props: Props) {

  const [posts, setPosts] = useState<Post[]>([]);
  const [listLoading, setListLoading] = useState(false);

  useEffect(() => {
    setListLoading(true);
    getPostList()
      .then((res) => {
        setPosts(res.data.data);
      })
      .finally(() => {
        setListLoading(false);
      })
  }, [])

  const listItemMarkup = useMemo(() => {
    if (listLoading) return <BlockLoading />;
    return (posts.map((item) => (
      <Fragment key={item.postHash}>
        <HomeListItem post={item} />
      </Fragment>
    ))
    )
  }, [listLoading, posts])


  return (
    <div className="panel-main">
      <ol className="home-ol">
        {listItemMarkup}
      </ol>
    </div>


  )

}

export default HomePostList;