import { useEffect } from "react";
import { useNavigate } from "react-router";

const RedirectToWelcome = () => {
    const nav = useNavigate();
    
    useEffect(() => {
        nav("/welcome");
    }, []);

    return ( 
        <></>
     );
}
 
export default RedirectToWelcome;