import { Outlet } from "react-router";
import "./BlogFrame.scss";
import { BlogMenu } from "./blogMenu/BlogMenu";
// import avatar from "../../../asset/picture/shuini.png";
import avatar from "../../../asset/picture/logo.png";
import "../Blog.scss";
import windmill from "../../../asset/picture/windmill.png";
import SiteLogo from "../../../component/SiteLogo";

function BlogFrame() {

  return (
    <section className={`blog-frame`}>
      <header>
        <SiteLogo />
        {/* <p className="freedom">
          <i>Sell not virtue to purchase wealth, nor liberty to purchase power.</i><i>–-Benjamin Franklin</i>
        </p> */}
      </header>
      <main>
        <div className={`container`}>
          <div className="content-panel">
            
            <BlogMenu />
            <Outlet></Outlet>
          </div>

        </div>
      </main>
      <footer>
        <div>🏳️‍🌈 🇺🇦</div>
          2022 ~ {new Date().getFullYear()} 
        {/* <i className="windmill"><img src={windmill} alt="Stand with Ukraine" /> </i> */}
      </footer>
    </section>
  )
}

export default BlogFrame;