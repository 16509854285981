import { ReactNode } from "react";

interface Props{
  children ?: ReactNode
}

function AppFrame(props: Props){

  return(
    <div style={{minHeight: "100vh"}}>
      { props.children }
    </div>
  )
}

export default AppFrame;