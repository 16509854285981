import React from 'react'

const HobbiesPanel = () => {
  return (
    <div className="panel flex justify-center items-center bg-[#569C69]">
      <span className="absolute right-0 bottom-0 text-[200px] font-bold text-[#48B164] z-0">Hobbies</span>
      <ul className="text-[2em] text-[#FFFFE3]">
        <li>喜欢猫，信仰猫教</li>
        <li>折腾安卓手机 最喜欢的custom rom Pixel Experience</li>
        <li>Openwrt 使用自己打包编译x86镜像</li>
        <li>健身 对自己的颈椎腰椎好一点，狗命要紧</li>
        <li>炒菜 主要是中华料理，最擅长炒鸡</li>
        <li>喜欢咖啡馆</li>
      </ul>
    </div>
  )
}

export default HobbiesPanel