
import avatar from "../asset/picture/logo.png";


function SiteLogo() {
    return (
          <div className="avatar">
            <img src={avatar} alt="hello," />
          </div>
    )
  }
  
export default SiteLogo;