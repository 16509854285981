import Axios, { AxiosInstance } from "axios";
import { KEY_TOKEN } from "../common/static";

const Ax: AxiosInstance = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ORIGIN}/api`,
})


Ax.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json';
    return config
  },
  error => {
    Promise.reject(error)
  }
)

const AxUpload: AxiosInstance = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ORIGIN}/api`,
})


export { Ax, AxUpload };